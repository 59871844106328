import { Controller } from "@hotwired/stimulus";
import { setCookie, getCookie } from "./cookies";

const EVENT_COOKIE_NAME = "dispatch-event";

export default class extends Controller {
    static values = {
        eventOnConnect: String,
        delayTo: String,
        eventFromCookie: Boolean,
        eventIfExistsInCookie: String,
        ifNotHidden: Boolean,
    };

    getEventCookie() {
        return getCookie(EVENT_COOKIE_NAME);
    }

    setEventCookie(value, attributes = {}) {
        return setCookie(EVENT_COOKIE_NAME, value, attributes);
    }

    _dispatchEvent(event) {
        const parts = event.split("@");
        let target = "";
        if (parts.length > 1) {
            target = parts[1];
        }
        if (target === "window") {
            dispatchEvent(new Event(parts[0]));
        } else if (target === "document") {
            document.dispatchEvent(new Event(parts[0]));
        } else if (target === "element") {
            this.element.dispatchEvent(new Event(parts[0]));
        } else {
            dispatchEvent(new Event(parts[0]));
        }
    }

    delayOrDispatchEvent(event) {
        if (this.hasDelayToValue) {
            addEventListener(
                this.delayToValue,
                () => {
                    this._dispatchEvent(event);
                    this.setEventCookie("", { path: "/", "max-age": "-1" });
                },
                { once: true }
            );
        } else {
            this._dispatchEvent(event);
            this.setEventCookie("", { path: "/", "max-age": "-1" });
        }
    }

    connect() {
        if (!this.hasEventOnConnectValue && !this.hasEventFromCookieValue && !this.hasEventIfExistsInCookieValue) {
            return;
        }
        if (this.ifNotHiddenValue) {
            const style = window.getComputedStyle(this.element);
            if (style.display === "none") {
                return;
            }
        }
        if (this.hasEventOnConnectValue) {
            const event = this.eventOnConnectValue;
            if (!event) {
                return;
            }
            this.delayOrDispatchEvent(event);
        } else if (this.hasEventFromCookieValue) {
            const [event, found] = getCookie(EVENT_COOKIE_NAME);
            if (!found) {
                return;
            }
            this.delayOrDispatchEvent(event);
        } else if (this.eventIfExistsInCookieValue !== "") {
            const [event, found] = getCookie(EVENT_COOKIE_NAME);
            if (!found) {
                return;
            }
            if (this.eventIfExistsInCookieValue !== event) {
                return;
            }
            this.delayOrDispatchEvent(event);
        }
    }

    event({ params }) {
        if (!params.event) {
            return;
        }
        const event = params.event;
        if (!event) {
            return;
        }
        this._dispatchEvent(event);
    }
}

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    submitAfterInput(event) {
        console.log("🥨");
        if (this.timer) {
            clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
            const { submitButtonId } = event.params;
            const submitBtn = this.element.querySelector("#" + submitButtonId);
            if (!submitBtn) {
                console.warn(`missing submit button ${submitButtonId}`);
                return;
            }
            submitBtn.click();
        }, 250);
    }
}
